import i18n from '@/libs/i18n'

export default [
    {
        path: '/equipments/vehicles',
        name: 'vehicles',
        component: () => import('@/views/table/vue-good-table/Vehicles'),
        meta: {
            pageTitle: i18n.t('Vehicles'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Vehicles'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/vehicle/:id',
        name: 'vehicle',
        component: () => import('@/views/table/vue-good-table/VehicleEditCreate'),
        meta: {
            pageTitle: i18n.t('Vehicle'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Vehicle'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/vehicle_document/:id/:vehicle_id',
        name: 'vehicle_document',
        component: () => import('@/views/table/vue-good-table/VehicleDocumentEditCreate'),
        meta: {
            pageTitle: i18n.t('Vehicle Document'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Vehicle Document'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/equipments',
        name: 'equipments',
        component: () => import('@/views/table/vue-good-table/Equipments'),
        meta: {
            pageTitle: i18n.t('Equipments'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Equipments'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/equipment/:id',
        name: 'equipment',
        component: () => import('@/views/table/vue-good-table/EquipmentEditCreate'),
        meta: {
            pageTitle: i18n.t('Equipment'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Equipment'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/import-vehicles',
        name: 'import-vehicles',
        component: () => import('@/views/table/vue-good-table/ImportVehicles.vue'),
        meta: {
            pageTitle: i18n.t('Import Vehicles'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Import Vehicles'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/import-equipments',
        name: 'import-equipments',
        component: () => import('@/views/table/vue-good-table/ImportEquipments.vue'),
        meta: {
            pageTitle: i18n.t('Import Equipments'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Import Equipments'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/equipment_document/:id/:equipment_id',
        name: 'equipment_document',
        component: () => import('@/views/table/vue-good-table/EquipmentDocumentEditCreate'),
        meta: {
            pageTitle: i18n.t('Equipment Document'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Equipment Document'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },

    {
        path: '/equipments/plants',
        name: 'plants',
        component: () => import('@/views/table/vue-good-table/Plants.vue'),
        meta: {
            pageTitle: i18n.t('Plants'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Plants'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/plant/:id',
        name: 'plant',
        component: () => import('@/views/table/vue-good-table/PlantEditCreate'),
        meta: {
            pageTitle: i18n.t('Plant'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Plant'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
    {
        path: '/equipments/plant_document/:id/:plant_id',
        name: 'plant_document',
        component: () => import('@/views/table/vue-good-table/PlantDocumentEditCreate'),
        meta: {
            pageTitle: i18n.t('Plant Document'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Plant Document'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },

    {
        path: '/equipments/import-plants',
        name: 'import-plants',
        component: () => import('@/views/table/vue-good-table/ImportPlants.vue'),
        meta: {
            pageTitle: i18n.t('Import Plants'),
            breadcrumb: [
                {
                    text: i18n.t('Equipments'),
                },
                {
                    text: i18n.t('Import Plants'),
                    active: true,
                },
            ],
            resource: 'Basic Equipments',
            action: 'read'
        },
    },
]

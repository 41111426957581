<template>
  <div class="col-xs-12" :style="style"></div>
</template>

<script>
export default {
  name: "spacer",
  props: ['height'],
  computed: {
    style(){
      return "height:"+this.height+"px;"
    }
  }
}
</script>

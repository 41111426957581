import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'
import {environment} from "@/auth/environment";

const config = {
    // Endpoint
    loginEndpoint: environment.baseURL + '/login',
    registerEndpoint: environment.baseURL + '/register',
    refreshEndpoint: environment.baseURL + '/refresh',
    logoutEndpoint: environment.baseURL + '/logout',
    profileEndpoint: environment.baseURL + '/profile',

    tokenType: 'Bearer',

    storageTokenKeyName: 'access_token',
    storageRefreshTokenKeyName: 'refresh_token',
}

const {jwt} = useJwt(axios, config)
export default jwt

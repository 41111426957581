import i18n from '@/libs/i18n'

export default [
    {
        path: '/dashboard/calendar',
        name: 'apps-calendar',
        component: () => import('@/views/apps/calendar/Calendar.vue'),
        meta: {
            pageTitle: i18n.t('Calendar'),
            breadcrumb: [
                {
                    text: i18n.t('Dashboard'),
                },
                {
                    text: i18n.t('Calendar'),
                    active: true,
                },
            ],
            resource: 'platform',
            action: 'read'
        }
    },
    {
        path: '/library/document-sections',
        name: 'document-sections',
        component: () => import('@/views/table/vue-good-table/DocumentSections.vue'),
        meta: {
            pageTitle: i18n.t('Document Sections'),
            breadcrumb: [
                {
                    text: i18n.t('Library'),
                },
                {
                    text: i18n.t('Document Sections'),
                    active: true,
                },
            ],
            resource: 'Basic Library',
            action: 'read'
        },
    },
    {
        path: '/library/document-section/:id',
        name: 'document-section',
        component: () => import('@/views/table/vue-good-table/DocumentSectionEditCreate'),
        meta: {
            pageTitle: i18n.t('Document Section'),
            breadcrumb: [
                {
                    text: i18n.t('Library'),
                },
                {
                    text: i18n.t('Document Section'),
                    active: true,
                },
            ],
            resource: 'platform',
            action: 'read'
        },
    },
    {
        path: '/library/documents/:document_section_id',
        name: 'documents',
        component: () => import('@/views/table/vue-good-table/Documents.vue'),
        meta: {
            pageTitle: i18n.t('Documents'),
            breadcrumb: [
                {
                    text: i18n.t('Library'),
                },
                {
                    text: i18n.t('Documents'),
                    active: true,
                },
            ],
            resource: 'platform',
            action: 'read'
        },
    },
    {
        path: '/library/document/:id/:section_id',
        name: 'document',
        component: () => import('@/views/table/vue-good-table/DocumentEditCreate'),
        meta: {
            pageTitle: i18n.t('Document'),
            breadcrumb: [
                {
                    text: i18n.t('Library'),
                },
                {
                    text: i18n.t('Document'),
                    active: true,
                },
            ],
            resource: 'platform',
            action: 'read'
        },
    },
]

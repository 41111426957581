export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    meta:{
      resource: 'platform',
      action: 'read'
    }

  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    meta:{
      resource: 'platform',
      action: 'read'
    }
  },

  {
    path: '/dashboard/expirations',
    name: 'dashboard-expirations',
    component: () => import('@/views/table/vue-good-table/Expirations.vue'),
    meta:{
      resource: 'platform',
      action: 'read'
    }
  },

  {
    path: '/dashboard/expirations-admin',
    name: 'dashboard-expirations-admin',
    component: () => import('@/views/table/vue-good-table/AdminExpirations.vue'),
    meta:{
      resource: 'admin',
      action: 'read'
    }
  },

  {
    path: '/dashboard/print-declarations',
    name: 'print-declarations',
    component: () => import('@/views/table/vue-good-table/EmployeesDeclaration.vue'),
    meta:{
      resource: 'platform',
      action: 'read'
    }
  },



  {
    path: '/dashboard/module_not_enabled',
    name: 'module-not-enabled',
    component: () => import('@/views/error/ModuleNotEnabled.vue'),
    meta:{
      resource: 'platform',
      action: 'read'
    }
  },

]

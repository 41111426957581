import i18n from '@/libs/i18n'

export default [
    {
        path: '/hr/assignments',
        name: 'assignments',
        component: () => import('@/views/table/vue-good-table/Assignments'),
        meta: {
            pageTitle: i18n.t('Assignments'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Assignments'),
                    active: true,
                },
            ],
            resource: 'admin',
            action: 'manage'
        },
    },
    {
        path: '/hr/risks',
        name: 'risks',
        component: () => import('@/views/table/vue-good-table/Risks'),
        meta: {
            pageTitle: i18n.t('Risks'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Risks'),
                    active: true,
                },
            ],
            resource: 'admin',
            action: 'manage'
        },
    },
    {
        path: '/hr/ppes',
        name: 'ppes',
        component: () => import('@/views/table/vue-good-table/PersonalProtectiveEquipment'),
        meta: {
            pageTitle: i18n.t('PersonalProtectiveEquipments'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('PersonalProtectiveEquipments'),
                    active: true,
                },
            ],
            resource: 'admin',
            action: 'manage'
        },
    },
    {
        path: '/hr/ppe',
        name: 'ppe',
        component: () => import('@/views/table/vue-good-table/PPEEditCreate'),
        meta: {
            pageTitle: i18n.t('PersonalProtectiveEquipment'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('PersonalProtectiveEquipment'),
                    active: true,
                },
            ],
            resource: 'admin',
            action: 'manage'
        },
    },
    {
        path: '/hr/employees',
        name: 'employees',
        component: () => import('@/views/table/vue-good-table/Employees.vue'),
        meta: {
            pageTitle: i18n.t('Employees'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Employees'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/import-employees',
        name: 'import-employees',
        component: () => import('@/views/table/vue-good-table/ImportEmployees.vue'),
        meta: {
            pageTitle: i18n.t('Import Employees'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Import Employees'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/employee/:id',
        name: 'employee',
        component: () => import('@/views/table/vue-good-table/EmployeeEditCreate'),
        meta: {
            pageTitle: i18n.t('Employee'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Employee'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/documents/:document_section_id',
        name: 'documents',
        component: () => import('@/views/table/vue-good-table/Documents.vue'),
        meta: {
            pageTitle: i18n.t('Documents'),
            breadcrumb: [
                {
                    text: i18n.t('Library'),
                },
                {
                    text: i18n.t('Documents'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/documents/:id',
        name: 'document',
        component: () => import('@/views/table/vue-good-table/DocumentEditCreate'),
        meta: {
            pageTitle: i18n.t('Document'),
            breadcrumb: [
                {
                    text: i18n.t('Library'),
                },
                {
                    text: i18n.t('Document'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/course/:id/:employee_id',
        name: 'course',
        component: () => import('@/views/table/vue-good-table/CourseEditCreate'),
        meta: {
            pageTitle: i18n.t('Course'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Course'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    }, {
        path: '/hr/document/:id/:employee_id',
        name: 'employee_document',
        component: () => import('@/views/table/vue-good-table/EmployeeDocumentsEditCreate'),
        meta: {
            pageTitle: i18n.t('Employee Document'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Employee Document'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/medical_checkup/:id/:employee_id',
        name: 'medical_checkup',
        component: () => import('@/views/table/vue-good-table/MedicalCheckupEditCreate'),
        meta: {
            pageTitle: i18n.t('Medical Checkup'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Medical Checkup'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/departments',
        name: 'departments',
        component: () => import('@/views/table/vue-good-table/Departments'),
        meta: {
            pageTitle: i18n.t('Departments'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Departments'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    },
    {
        path: '/hr/roles',
        name: 'roles',
        component: () => import('@/views/table/vue-good-table/Roles'),
        meta: {
            pageTitle: i18n.t('Roles'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Roles'),
                    active: true,
                },
            ],
            resource: 'Basic HR',
            action: 'read'
        },
    }, {
        path: '/hr/role_templates',
        name: 'role_templates',
        component: () => import('@/views/table/vue-good-table/RoleTemplates'),
        meta: {
            pageTitle: i18n.t('Roles Templates'),
            breadcrumb: [
                {
                    text: i18n.t('HR'),
                },
                {
                    text: i18n.t('Roles Templates'),
                    active: true,
                },
            ],
            resource: 'admin',
            action: 'manage'
        },
    },
]

import i18n from "@/libs/i18n";

export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    route: 'dashboard-ecommerce',
    resource: 'Basic Library',
    action: 'read'
  },

]

import i18n from '@/libs/i18n'

export default [
  {
    path: '/admin/companies',
    name: 'companies',
    component: () => import('@/views/table/vue-good-table/Companies.vue'),
    meta: {
      pageTitle: i18n.t('Companies'),
      breadcrumb: [
        {
          text: i18n.t('Admin'),
        },
        {
          text: i18n.t('Companies'),
          active: true,
        },
      ],
      resource: 'Basic Admin',
      action: 'read'
    },
  },
  {
    path: '/admin/company/:id',
    name: 'company',
    component: () => import('@/views/table/vue-good-table/CompanyEditCreate.vue'),
    meta: {
      pageTitle: i18n.t('Company'),
      breadcrumb: [
        {
          text: i18n.t('Admin'),
        },
        {
          text: i18n.t('Company'),
          active: true,
        },
      ],
      resource: 'Basic Admin',
      action: 'read'
    },

  },

  {
    path: '/admin/user/:id/:company_id',
    name: 'user',
    component: () => import('@/views/table/vue-good-table/UserEditCreate.vue'),
    meta: {
      pageTitle: i18n.t('User'),
      breadcrumb: [
        {
          text: i18n.t('Admin'),

        },
        {
          text: i18n.t('User'),
          active: true,
        },
      ],
      resource: 'Basic Admin',
      action: 'read'
    },

  },
]

import i18n from '@/libs/i18n'

export default [
    {
        path: '/files/finder',
        name: 'filefinder',
        component: () => import('@/views/apps/todo/FileFinder.vue'),
        meta: {
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
            resource: 'Basic Files',
            action: 'read'
        },
    },
   

]

<template>

  <div>
    <div v-if="!showDropdown">

      <b-img
          :src="currentCompany.logo"
          height="36px"
          :alt="currentCompany.name"
      />
    </div>
    <b-nav-item-dropdown v-if="showDropdown"
                         id="dropdown-grouped"
                         variant="link"
                         class="dropdown-language"
                         right
    >
      <template #button-content>
        <b-img
            :src="currentCompany.logo"
            height="36px"
            :alt="currentCompany.name"
        />
        <span class="ml-50 text-body">{{ currentCompany.name }}</span>
      </template>
      <b-dropdown-item
          v-for="company in companies"
          :key="company.id"
          @click="setCompany(company)"
      >
        <b-img
            :src="company.logo"
            height="14px"
            :alt="company.name"
        />
        <span class="ml-50">{{ company.name }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

  </div>

</template>

<script>
import {BNavItemDropdown, BDropdownItem, BImg} from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {

    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      userCompany: JSON.parse(localStorage.getItem('userCompany')),
      companies: JSON.parse(localStorage.getItem('companies')),
    }
  },

  computed: {
    currentCompany() {
      //console.log("role",store.state.loggedUser.role)
      //return this.companies.find(l => l.id === this.userCompany.id)
      return this.userCompany;
    },
    showDropdown() {

      let show = (this.userData && this.userData.role === 'admin');
      console.log("showDropdown", show)
      return show
    }
  },
  mounted() {
    console.log('userData', this.userData)

    if (this.userData && this.userData.role === 'admin') {
      console.log('companies on array', this.companies)
    } else {
      console.log('not admin', this.userData)
    }
    store.state.userCompany = this.userCompany;
  },
  methods: {
    setCompany(company) {
      localStorage.setItem('userCompany', JSON.stringify(company))
      this.userCompany = company;
      store.commit('selected_company', this.userCompany)
    }
  }

}
</script>

<style>

</style>

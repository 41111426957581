import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import ExportExcel from "@/views/components/excel-export/ExportExcel";
import ImportExcel from "@/views/components/excel-export/ImportExcel";
import BasicLayout from "@/layouts/vertical/BasicLayout";
import CompanySelector from "@/layouts/vertical/CompanySelector";
import Spacer from "@/views/components/spacer/Spacer";


Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component("export-excel", ExportExcel);
Vue.component("import-excel", ImportExcel);
Vue.component("layout-vertical", BasicLayout);
Vue.component("company-selector", CompanySelector);
Vue.component("spacer", Spacer);


import i18n from '@/libs/i18n'

export default [
    {
        path: '/legal/legal-regulations',
        name: 'legal-regulations',
        component: () => import('@/views/table/vue-good-table/LegalRegulations'),
        meta: {
            pageTitle: i18n.t('Legal Regulations'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('Legal Regulations'),
                    active: true,
                },
            ],
            resource: 'Basic Legal Obligations',
            action: 'read'
        },
    },
    {
        path: '/legal/legal-regulation/:id',
        name: 'legal-regulation',
        component: () => import('@/views/table/vue-good-table/LegalRegulationEditCreate'),
        meta: {
            pageTitle: i18n.t('Legal Regulation'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('LegalRegulation'),
                    active: true,
                },
            ],
            resource: 'Basic Admin',
            action: 'read'
        },
    },
    {
        path: '/legal/fulfillments/:legal_regulation_id',
        name: 'fulfillments',
        component: () => import('@/views/table/vue-good-table/Fulfillments'),
        meta: {
            pageTitle: i18n.t('Fulfillments'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('Fulfillments'),
                    active: true,
                },
            ],
            resource: 'Basic Legal Obligations',
            action: 'read'
        },
    }
    ,{
        path: '/legal/company_fulfillments/:company_id/:legal_regulation_id',
        name: 'company-fulfillments',
        component: () => import('@/views/table/vue-good-table/CompanyFulfillments'),
        meta: {
            pageTitle: i18n.t('Fulfillments'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('Fulfillments'),
                    active: true,
                },
            ],
            resource: 'Basic Legal Obligations',
            action: 'read'
        },
    },
    {
        path: '/legal/company-legal-regulations',
        name: 'company-legal-regulations',
        component: () => import('@/views/table/vue-good-table/CompanyLegalRegulations'),
        meta: {
            pageTitle: i18n.t('Legal Regulations'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('Legal Regulations'),
                    active: true,
                },
            ],
            resource: 'Basic Legal Obligations',
            action: 'read'
        },
    },
    {
        path: '/legal/fulfillment/:id',
        name: 'fulfillment',
        component: () => import('@/views/table/vue-good-table/FulfillmentEditCreate'),
        meta: {
            pageTitle: i18n.t('Fulfillment'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('Fulfillment'),
                    active: true,
                },
            ],
            resource: 'Basic Legal Obligations',
            action: 'read'
        },
    },
 {
        path: '/legal/company_fulfillment/:id',
        name: 'company_fulfillment',
        component: () => import('@/views/table/vue-good-table/CompanyFulfillmentEditCreate'),
        meta: {
            pageTitle: i18n.t('Fulfillment'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('Fulfillment'),
                    active: true,
                },
            ],
            resource: 'Basic Legal Obligations',
            action: 'read'
        },
    },
    {
        path: '/legal/company_fulfillment_document/:id/:company_fulfillment_id',
        name: 'company_fulfillment_document',
        component: () => import('@/views/table/vue-good-table/CompanyFulfillmentDocumentEditCreate'),
        meta: {
            pageTitle: i18n.t('Fulfillment Document'),
            breadcrumb: [
                {
                    text: i18n.t('Legal Obligations'),
                },
                {
                    text: i18n.t('Fulfillment Document'),
                    active: true,
                },
            ],
            resource: 'Basic Legal Obligations',
            action: 'read'
        },
    },
]

import i18n from '@/libs/i18n'

export default [
  {
    header: i18n.t('Library'),
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Documents'),
    route: {name: 'document-sections'},
    icon: 'FolderIcon',
    resource: 'platform',
    action: 'read',
  },

  {
    header: i18n.t('Basic HR'),
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Role Templates'),
    route: 'role_templates',
    icon: 'ListIcon',
    resource: 'admin',
    action: 'manage'
  },

  {
    title: i18n.t('Assignments'),
    route: 'assignments',
    icon: 'CheckSquareIcon',
    resource: 'admin',
    action: 'manage'
  },  {
    title: i18n.t('Risks'),
    route: 'risks',
    icon: 'AlertCircleIcon',
    resource: 'admin',
    action: 'manage'
  }, {
    title: i18n.t('PersonalProtectiveEquipments'),
    route: 'ppes',
    icon: 'ShieldIcon',
    resource: 'admin',
    action: 'manage'
  },
  {
    title: i18n.t('Employees'),
    route: 'employees',
    icon: 'UserIcon',
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Departments'),
    route: 'departments',
    icon: 'MapPinIcon',
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Roles'),
    route: 'roles',
    icon: 'ListIcon',
    resource: 'platform',
    action: 'read'
  },
  {
    header: i18n.t('Basic Equipments'),
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Vehicles'),
    route: 'vehicles',
    icon: 'TruckIcon',
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Equipments'),
    route: 'equipments',
    icon: 'ToolIcon',
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Plants'),
    route: 'plants',
    icon: 'HomeIcon',
    resource: 'platform',
    action: 'read'
  },
  {
    header: i18n.t('Basic Legal Obligations'),
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Generic Legal Regulations'),
    route: 'legal-regulations',
    icon: 'BookIcon',
    resource: 'admin',
    action: 'manage'
  },
  {
    title: i18n.t('Legal Regulations'),
    route: 'company-legal-regulations',
    icon: 'BookIcon',
    resource: 'platform',
    action: 'read'
  },
  {
    header: i18n.t('Basic Files'),
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Files'),
    route: 'filefinder',
    icon: 'FileTextIcon',
    resource: 'platform',
    action: 'read'
  },

  {
    header: i18n.t('Utilities'),
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Expirations calendar'),
    route: {name: 'dashboard-expirations'},
    icon: 'CalendarIcon',
    resource: 'client',
    action: 'manage',
  },
  {
    title: i18n.t('Expirations calendar'),
    route: {name: 'dashboard-expirations-admin'},
    icon: 'CalendarIcon',
    resource: 'admin',
    action: 'manage',
  },
  {
    title: i18n.t('Employer declaration'),
    route: {name: 'print-declarations'},
    icon: 'PrinterIcon',
    resource: 'platform',
    action: 'read',
  },
  {
    header: i18n.t('Basic Admin'),
    resource: 'platform',
    action: 'read'
  },
  {
    title: i18n.t('Companies'),
    route: 'companies',
    icon: 'UsersIcon',
    resource: 'platform',
    action: 'read'
  },

]

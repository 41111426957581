const common = {
    version: "v1",
    locale: "it"
}

const production = {
    baseURL: "https://api.basic.italia.ae/api",
    baseDomain: "https://basic.italia.ae/",
}

const local = {
    baseURL: "http://127.0.0.1:8000/api",
    baseDomain: "http://localhost:8080/",
    locale: "it",
}

export const environment = Object.assign(common, process.env.NODE_ENV === 'development' ? local : production)

